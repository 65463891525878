import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { buildConfigurationListCategoriesUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';
import { ListCategoriesResponse, Category } from '../types';

export const loadListCategories = (
    actionName: string,
    processResultFunction: Function,
): Observable<{
    result: Category[];
    actionName: string;
    processResultFunction: Function;
}> => {
    const obs = sendRetrieveRequest(buildConfigurationListCategoriesUrl());
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<ListCategoriesResponse>) => {
            const results = convertToCamel<Category[]>(response.ListCategory);
            return observableOf({ result: results, actionName, processResultFunction });
        }),
    );
};
